@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap')

.container-row
    min-height: 80vh
    padding: 50px 0
    &#landing
        background-image: url('./landing_mobile.png')
        background-position: center center
        @media screen and (min-width: 480px)
            background-image: url('./landing.png')
    &:first-child
        height: 100vh
        position: relative
        .overlay
            background-color: rgba(0, 0, 0, 0.5)
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            overflow: hidden
        .ant-typography
            color: white !important
        .ant-space
            z-index: 1
    &:nth-child(odd)
        //background-color: #eaf1fc
        background-color: #fff
        .ant-typography, .ant-card-meta-description
            color: #201678
    &:nth-child(even)
        //background-color: #201678
        background-color: #ffede6
        .ant-typography
            //color: #fff
            color: #3a467d
            
    .ant-space-item
        width: 100%
        .para.ant-typography
            max-width: 320px
            font-size: 18px
            text-align: left
            @media screen and (min-width: 480px)
                max-width: 420px
        h2.para.ant-typography
            font-size: 24px
            max-width: 320px
            text-align: unset
            @media screen and (min-width: 480px)
                max-width: 420px
    .ant-card
        .ant-card-meta-title
            white-space: unset
        img
            cursor: pointer
    .ant-carousel
        .slick-slide
            display: flex
            justify-content: center
            align-items: middle
        .slick-dots
            li
                button
                    background: #201678

    .ant-steps-item-wait, .ant-steps-item-process, .ant-steps-item-finish
        > .ant-steps-item-container 
            > .ant-steps-item-content 
                > .ant-steps-item-title
                    //color: #fff
        .ant-steps-item-icon
            background: #3a467d
            > .ant-steps-icon
                //color: #000

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon
        color: #fff

    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon
        color: #fff

    .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon
        color: #fff
    
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description
        color: #3a467d
        
    .ant-timeline
        .ant-timeline-item-left, .ant-timeline-item-right 
            .ant-timeline-item-content
                margin-bottom: 50px
             
        .ant-timeline-item-left
            .ant-timeline-item-content
                padding-left: 25px
        .ant-timeline-item-right 
            .ant-timeline-item-content
                padding-right: 25px


    .card-special.ant-card
        //background-color: #170e64
        border-radius: 48px
        min-height: 300px
        padding: 10px
        flex-flow: wrap
        display: flex !important
        align-items: center
        .ant-card-meta-detail
            max-width: 320px
            @media screen and (min-width: 480px)
                max-width: 420px
        h3
            text-align: center
        .ant-card-meta-description
            font-size: 16px
            //color: #fff
        @media screen and (min-width: 480px)
            padding: 40px

    .col-card
        padding: 40px 16px
        @media screen and (min-width: 480px)
            padding: 40px

    @media screen and (max-width: 480px)
        .ant-steps-horizontal
            &:not(.ant-steps-label-vertical) 
                .ant-steps-item
                    padding-left: unset !important
    

.ant-btn-primary
    background: #3a467d
    border-color: #3a467d
    &:hover, &:focus
        background: #3a467d
        border-color: #3a467d