body
    -moz-osx-font-smoothing: grayscale
    text-rendering: optimizeLegibility
    font-family: 'Josefin Sans', sans-serif
    font-size: 16px

//.logo
    //align-items: center
    //display: flex
    //height: 30px
    //justify-content: center
    //width: 50px

header
    align-items: center
    display: flex
    justify-content: center
    position: fixed
    width: 100%
    z-index: 2
    background: #fff !important
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1)
    @media screen and (min-width: 480px)
        justify-content: space-between    
    #menu
        display: none
        @media screen and (min-width: 480px)
            display: flex
            a, .ant-btn
                display: block
footer
    font-size: 16px !important
    .logo
        background: rgba(0, 0, 0, 0.2)
        color: #000
    .anticon
        svg
            height: 1.5em
            width: 1.5em
    .ant-col
        margin-bottom: 10px
        #contactUs
            @media screen and (max-width: 480px)
                display: block

a
    color: #3a467d
    font-weight: bold